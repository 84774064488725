// Load Styles
import '../scss/main.scss';

import $ from 'jquery';

import 'slick-carousel';
import 'jquery-match-height';

// Load Bootstrap init
import { initBootstrap } from "./bootstrap.js";

// Loading bootstrap with optional features
initBootstrap({
  tooltip: false,
  popover: false,
  toasts: false,
});

// Your app code
// console.log(`Hello ${process.env.HELLO}`);

$(function () {
  //## jQuery
  // const winH = $(window).height();
  // console.log(`Window height: ${winH}`);
  // const winW = $(window).width();
  // console.log(`Window width: ${winW}`);

  //## matchHeight
  if ($.fn.matchHeight) {
    $('.same-height').matchHeight({
      byRow: true,
      property: 'height',
      target: null,
      remove: false,
    });
  }
  //## Slick
  if ($.fn.slick) {
    $("#banners.slider").slick({
      dots: false,
      arrows: true,
      infinite: false,
      autoplay: false,
      // autoplaySpeed: 1000,
      speed: 500,
      // slidesToShow: 1,
      // slidesToScroll: 1,
      // variableWidth: true,
      fade: true,
      cssEase: 'linear',
      responsive: [
        // {
        //   breakpoint: 1024,
        //   settings: {
        //     dots: false,
        //   },
        // },
        // {
        //   breakpoint: 600,
        //   settings: {
        //     dots: false,
        //   },
        // },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            dots: true,
          },
        },
      ],
    });

    $("#brand-list").slick({
      dots: false,
      arrows: true,
      infinite: true,
      autoplay: false,
      // autoplaySpeed: 1000,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      // variableWidth: true,
      // centerMode: true,
      // fade: true,
      // cssEase: 'linear',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: false,
            dots: true,
          },
        },
      ],
    });

    $("#customer-list").slick({
      dots: false,
      arrows: true,
      infinite: true,
      // autoplay: false,
      // autoplaySpeed: 1000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '60px',
      variableWidth: true,
      // fade: true,
      // cssEase: 'linear',
      // responsive: [
      //   {
      //     breakpoint: 1024,
      //     settings: {
      //       dots: false,
      //     },
      //   },
      //   // {
      //   //   breakpoint: 600,
      //   //   settings: {
      //   //     dots: false,
      //   //   },
      //   // },
      //   {
      //     breakpoint: 480,
      //     settings: {
      //       dots: true,
      //     },
      //   },
      // ],
    });

    // $("#solution-robot").slick({
    //   dots: false,
    //   arrows: false,
    //   infinite: false,
    //   autoplay: false,
    //   // autoplaySpeed: 1000,
    //   speed: 500,
    //   slidesToShow: 5.25,
    //   slidesToScroll: 1,
    //   // centerPadding: '40px',
    //   // variableWidth: true,
    //   // centerMode: true,
    //   // fade: true,
    //   // cssEase: 'linear',
    //   responsive: [
    //     {
    //       breakpoint: 1600,
    //       settings: {
    //         slidesToShow: 4.25,
    //         slidesToScroll: 1,
    //       },
    //     },
    //     {
    //       breakpoint: 1200,
    //       settings: {
    //         slidesToShow: 3.25,
    //         slidesToScroll: 1,
    //       },
    //     },
    //     {
    //       breakpoint: 960,
    //       settings: {
    //         slidesToShow: 2.25,
    //         slidesToScroll: 1,
    //       },
    //     },
    //     {
    //       breakpoint: 678,
    //       settings: {
    //         slidesToShow: 2.25,
    //         slidesToScroll: 1,
    //       },
    //     },
    //     {
    //       breakpoint: 480,
    //       settings: {
    //         slidesToShow: 1.25,
    //         slidesToScroll: 1,
    //         // arrows: false,
    //         // dots: true,
    //       },
    //     },
    //     {
    //       breakpoint: 330,
    //       settings: {
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //         // arrows: false,
    //         // dots: true,
    //       },
    //     },
    //   ],
    // });
    // $("#solution-robot-2").slick({
    //   dots: false,
    //   arrows: false,
    //   infinite: false,
    //   autoplay: false,
    //   // autoplaySpeed: 1000,
    //   speed: 500,
    //   slidesToShow: 5.25,
    //   slidesToScroll: 1,
    //   // centerPadding: '40px',
    //   // variableWidth: true,
    //   centerMode: true,
    //   // fade: true,
    //   // cssEase: 'linear',
    //   responsive: [
    //     {
    //       breakpoint: 1600,
    //       settings: {
    //         slidesToShow: 4.25,
    //         slidesToScroll: 1,
    //       },
    //     },
    //     {
    //       breakpoint: 1200,
    //       settings: {
    //         slidesToShow: 3.25,
    //         slidesToScroll: 1,
    //       },
    //     },
    //     {
    //       breakpoint: 960,
    //       settings: {
    //         slidesToShow: 2.25,
    //         slidesToScroll: 1,
    //       },
    //     },
    //     {
    //       breakpoint: 678,
    //       settings: {
    //         slidesToShow: 2.25,
    //         slidesToScroll: 1,
    //       },
    //     },
    //     {
    //       breakpoint: 480,
    //       settings: {
    //         slidesToShow: 1.25,
    //         slidesToScroll: 1,
    //         // arrows: false,
    //         // dots: true,
    //       },
    //     },
    //     {
    //       breakpoint: 330,
    //       settings: {
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //         // arrows: false,
    //         // dots: true,
    //       },
    //     },
    //   ],
    // });
  }

  $(document).on('click', '.navbar-toggler', function () {
    if (!$(this).hasClass('collapsed')) {
      $('.account').removeClass('d-lg-block');
    } else {
      $('.account').addClass('d-lg-block');
    }
  });

});
